<template>
  <a
    href="#"
    class="flex flex-row items-center text-sm whitespace-nowrap relative"
    :class="linkClasses"
    role="menuitem"
    :data-test-id="dataTestId"
    @click.prevent="click"
  >
    <div class="inline-block h-full w-1 absolute left-0 top-0 flex-shrink-0" :class="{ 'bg-yb-brand-primary': selectionIndicator && selected && !checkSelected }" />
    <yb-icon v-show="selected" v-if="checkSelected" icon="check" class="absolute left-2 w-3 flex-shrink-0" />
    <yb-icon v-if="icon" :icon="icon" :class="iconClasses" />
    <span v-if="label" :class="{'yb-disable-text': disabled }" class="mr-3" :data-test-id="dataTestId + '-' + label">{{ label }}</span>
    <div v-else :class="wrapperClasses">
      <slot />
    </div>

    <slot name="righthand" />
  </a>
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
    iconClass: {
      type: String,
      default: 'yb-button-icon'
    },
    linkClass: {
      type: String,
      default: 'pl-4 pr-2 py-1'
    },
    textClass: {
      type: String,
      default: 'text-yb-gray-dark dark:text-yb-gray-faintest'
    },
    textHoverClass: {
      type: String,
      default: 'hover:bg-yb-rollover-light hover:text-gray-900 dark:hover:text-yb-black'
    },
    textSelectedClass: {
      type: String,
      default: 'bg-yb-rollover dark:text-yb-black'
    },
    wrapperClass: {
      type: String,
      default: 'flex-grow inline-block ml-3'
    },
    selected: Boolean,
    disabled: Boolean,
    checkSelected: Boolean,
    selectionIndicator: {
      type: Boolean,
      default: true
    },
    dataTestId: String
  },
  emits: ['click'],
  computed: {
    linkClasses() {
      const result = [this.linkClass]
      if (typeof this.textHoverClass === 'string') {
        result.push(this.textHoverClass)
      }
      if (this.selected && !this.checkSelected) {
        result.push(this.textSelectedClass)
      } else {
        result.push(this.textClass)
      }
      return result
    },
    iconClasses() {
      const result = [this.iconClass]
      if (this.disabled) {
        result.push('opacity-25')
      }
      result.push('flex-shrink-0')
      return result
    },
    wrapperClasses() {
      const result = [this.wrapperClass]
      if (this.disabled) {
        result.push('yb-disable-text')
      }
      return result
    }
  },
  methods: {
    click($event) {
      if (!this.disabled) {
        this.$emit('click', $event)
      }
    }
  }
}
</script>
